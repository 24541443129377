<template>
  <div class="project-table-wrapper">
    <table class="project-table">
      <thead class="thead-dark">
        <tr>
          <th
            v-for="(col, index) in columns"
            :key="index"
            :style="{ width: col.width ? col.width : 'auto' }"
            @click="col.sortable ? doSort(col.field) : false"
          >
            <div
              :class="{
                sortable: col.sortable,
                both: col.sortable,
                asc: setting.order === col.field && setting.sort === 'asc',
                desc: setting.order === col.field && setting.sort === 'desc',
              }"
            >
              <div>{{ col.label }}</div>
              <div
                v-if="setting.order === col.field && setting.sort === 'asc'"
                class="arrow-down"
              ></div>
              <div
                v-else-if="
                  setting.order === col.field && setting.sort === 'desc'
                "
                class="arrow-up"
              ></div>
              <div
                v-else-if="setting.order !== col.field && col.sortable"
                class="arrow-up"
              ></div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="rows && rows.length">
        <tr
          v-for="(row, i) in rows"
          :class="isHighlighted == row.id ? 'active' : ''"
          :key="i"
          @click="openProject(row.id)"
        >
          <td v-for="(col, j) in columns" :key="j">
            <div
              v-if="col.field === 'feasibility_number'"
              class="project-number"
            >
              <b>{{ row.feasibility_number }}</b>
            </div>
            <div
              v-else-if="col.field === 'project_number'"
              class="project-number"
            >
              <b>{{ row.project_number }}</b>
            </div>
            <div v-else-if="col.field === 'notify'" class="notify">
              <IconMail v-if="row.notify.hasMsg" icon-color="#17A2B8" />
              <IconFlame v-if="row.notify.hasProposal" :active="1" />
            </div>
            <div v-else v-html="row[col.field]"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    v-if="
      rows &&
      rows.length &&
      (paginationData.prev_page_url || paginationData.next_page_url)
    "
  >
    <div class="pagination-wrapper">
      <ul class="pagination">
        <li
          class="page-item"
          :class="{ disabled: paginationData.current_page <= 1 }"
        >
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="First"
            @click="movePage(paginationData.first_page_url)"
          >
            <span>&laquo;</span>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ disabled: paginationData.current_page <= 1 }"
        >
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="Previous"
            @click="movePage(paginationData.prev_page_url)"
          >
            <span>&lt;</span>
          </a>
        </li>
        <!-- <li
          class="page-item"
          v-for="n in paging"
          :key="n"
          :class="{ disabled: setting.current_page === n }"
        >
          <a
            class="page-link"
            href="javascript:void(0)"
            @click="setting.current_page = 1"
          >
            {{ n }}
          </a>
        </li> -->
        <li
          class="page-item"
          :class="{
            disabled: paginationData.current_page >= paginationData.last_page,
          }"
        >
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="Next"
            @click="movePage(paginationData.next_page_url)"
          >
            <span>&gt;</span>
          </a>
        </li>
        <li
          class="page-item"
          :class="{
            disabled: paginationData.current_page >= paginationData.last_page,
          }"
        >
          <a
            class="page-link"
            href="javascript:void(0)"
            aria-label="Next"
            @click="movePage(paginationData.last_page_url)"
          >
            <span>&raquo;</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import IconMail from "@/modules/components/icons/IconMail";
import IconFlame from "@/modules/components/icons/IconFlame";

export default {
  name: "my-table",
  components: { IconMail, IconFlame },
  emits: ["do-sort", "paginate"],
  props: {
    projectType: {
      type: String,
      default: "archive",
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    page: {
      type: Number,
      default: 1,
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          current_page: 1,
          first_page_url: null,
          from: 1,
          last_page: 1,
          last_page_url: null,
          next_page_url: null,
          path: null,
          per_page: 30,
          prev_page_url: null,
          to: null,
          total: null,
        };
      },
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc", // desc
        };
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const paginationData = computed(() => store.getters["projects/pagination"]);

    const setting = reactive({
      current_page: props.pagination.current_page,
      first_page_url: props.pagination.first_page_url,
      from: props.pagination.from,
      last_page: props.pagination.last_page,
      last_page_url: props.pagination.last_page_url,
      next_page_url: props.pagination.next_page_url,
      path: props.pagination.path,
      per_page: props.pagination.per_page,
      prev_page_url: props.pagination.prev_page_url,
      to: props.pagination.to,
      total: props.pagination.total,
      order: props.sortable.order,
      sort: props.sortable.sort,
    });

    const maxPage = computed(() => {
      return Math.ceil(setting.value.total / setting.value.per_page);
    });

    const paging = computed(() => {
      let startPage =
        setting.value.current_page - 2 <= 0
          ? 1
          : setting.value.current_page - 2;
      if (maxPage.value - setting.value.current_page <= 2) {
        startPage = maxPage.value - 4;
      }
      startPage = startPage <= 0 ? 1 : startPage;
      let pages = [];
      for (let i = startPage; i <= maxPage.value; i++) {
        // if (pages.length < 5) {
        pages.push(i);
        // }
      }
      return pages;
    });

    const isHighlighted = ref("");
    isHighlighted.value = route.query.p;

    const unSetHighlight = () => (isHighlighted.value = "");
    setTimeout(unSetHighlight, 3000);

    const movePage = (link) => {
      if (link) {
        emit("paginate", link.split("page=")[1]);
      }
    };

    const doSort = (order) => {
      let sort = "asc";
      if (order == setting.order) {
        if (setting.sort == "asc") {
          sort = "desc";
        }
      }
      setting.order = order;
      setting.sort = sort;
      emit("do-sort", order, sort);
    };

    const openProject = (id) => {
      let type = props.projectType;
      let name = "";

      switch (type) {
        case "draft":
          name = "ProjectEdit";
          break;
        case "feasibility":
          name = "ProjectFeasibility";
          break;
        case "project":
          name = "ProjectActive";
          break;
        case "archive":
          name = "ProjectArchive";
          break;
      }

      router.push({
        name,
        params: { id },
      });
      // let routeData = router.resolve({
      //   name,
      //   params: { id },
      // });
      // window.open(routeData.href, "_blank");
    };

    return {
      setting,
      maxPage,
      paging,
      isHighlighted,
      movePage,
      doSort,
      openProject,
      paginationData,
    };
  },
};
</script>

<style scoped lang="scss">
.sortable {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  div:nth-child(1) {
    padding-right: 10px;
  }
  .arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
  }
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }
}
tr {
  cursor: pointer;
  &:nth-child(even) {
    background-color: #6767670f;
  }
}
tbody tr:hover {
  background-color: #67676723;
}
.project-table {
  &-wrapper {
    overflow-x: auto;
    position: relative;
    // overflow-y: auto;
    // max-height: calc(100vh - 320px);

    // scroll:
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      &-track {
        box-shadow: inset 0 0 6px #2b29291f;
      }
      &-thumb {
        background-color: darkgrey;
      }
    }
  }

  min-width: 1200px;
  width: 100%;
  margin-bottom: 1rem;
  padding-right: 30px;
  color: #212529;
  border-collapse: collapse;

  .active {
    background-color: var(--col-info-bg);
  }

  .thead-dark th {
    border-right: 1px solid #fff;
    border-bottom: 2px solid #dee2e6;
  }

  .project-number {
    color: var(--col-additional);
  }
  td {
    position: relative;
    &:nth-child(1) {
      a {
        display: block;
        margin-top: -10px;
        padding-top: 10px;
        color: var(--col-additional);
        cursor: pointer;
      }
    }
    // &:nth-child(6) {
    //   font-weight: 900;
    // }
  }
  td,
  th {
    padding: 0.75rem;
    vertical-align: top;
    text-align: center;
    border-top: 1px solid #dee2e6;
  }
  .notify {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }
}
.pagination {
  &-wrapper {
    //position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
  }
  display: flex;
  justify-content: center;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: var(--col-additional);
  background-color: #fff;
  border: 1px solid #dee2e6;
}

@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
</style>
